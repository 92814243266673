import { Menu } from "@headlessui/react";
import { clsx } from "clsx";
import React, { MouseEvent, useState } from "react";
import ChevronIcon from "../assets/dropdown/icon-chevron-down.svg";
import DarkHCPExperienceIcon from "../assets/global-nav/icon-experience-w-text-dark.svg";
import HCPExperienceIcon from "../assets/global-nav/icon-experience-w-text.svg";
import DarkRecognitionIcon from "../assets/global-nav/icon-recognition-w-text-dark.svg";
import RecognitionIcon from "../assets/global-nav/icon-recognition-w-text.svg";
import DarkHCPRecruitIcon from "../assets/global-nav/icon-recruit-w-text-dark.svg";
import HCPRecruitIcon from "../assets/global-nav/icon-recruit-w-text.svg";
import DarkPinnacleRetainIcon from "../assets/global-nav/icon-retain-w-text-dark.svg";
import PinnacleRetainIcon from "../assets/global-nav/icon-retain-w-text.svg";
import DarkHCPTrainingIcon from "../assets/global-nav/icon-training-w-text-dark.svg";
import HCPTrainingIcon from "../assets/global-nav/icon-training-w-text.svg";
import MoreIcon from "../assets/icon-more.svg";
import {
  GlobalNavDropdown,
  GlobalNavDropdownItem,
  GlobalNavDropdownMenuItem,
  GlobalNavDropdownMenuItems,
} from "../components/common/Dropdown";
import { GlobalNavTab } from "../components/common/Tab";
import { TransitionHeight } from "../components/common/TransitionHeight";
import { GlobalNavVariation } from "../constants/constants";
import { makeUrl } from "../constants/strings";
import {
  ACTIVATED_INSIGHTS_PATH,
  HCP_EXPERIENCE_PATH,
  HCP_REPUTATION_PATH,
  HCP_TRAINING_PATH,
  PINNACLE_CAHPS_PATH,
  PINNACLE_QI_PATH,
  PINNACLE_RETAIN_PATH,
  RECRUIT_PATH,
} from "../constants/urls";
import { useCrossModalContext } from "../hooks/useCrossModalContext";
import { useGlobalNavContext } from "../hooks/useGlobalNavContext";
import {
  GlobalNavPermissions,
  usePermissionsContext,
} from "../hooks/usePermissionsContext";

const experienceItems: GlobalNavDropdownItem[] = [
  {
    href: HCP_EXPERIENCE_PATH,
    hostKey: "hcpExperienceAppHost",
    title: "HCP Experience Management",
    permissionsKey: "canAccessHcpExperience",
    modalVariation: GlobalNavVariation.HCP_EXPERIENCE,
  },
  {
    href: ACTIVATED_INSIGHTS_PATH,
    hostKey: "activatedInsightsAppHost",
    title: "Activated Insights",
    permissionsKey: "canAccessActivatedInsights",
    modalVariation: GlobalNavVariation.ACTIVATED_INSIGHTS,
  },
  {
    href: PINNACLE_QI_PATH,
    hostKey: "pinnacleQiAppHost",
    title: "Customer Satisfaction",
    permissionsKey: "canAccessPinnacleMain",
    modalVariation: GlobalNavVariation.PINNACLE_QI,
  },
  {
    href: PINNACLE_CAHPS_PATH,
    hostKey: "pinnacleCahpsAppHost",
    title: "CAHPS",
    permissionsKey: "canAccessPinnacleCAHPS",
    modalVariation: GlobalNavVariation.PINNACLE_CAHPS,
  },
];

const recognitionItems: GlobalNavDropdownItem[] = [
  {
    href: HCP_REPUTATION_PATH,
    hostKey: "hcpReputationAppHost",
    title: "Reputation Management",
    permissionsKey: "canAccessHcpReputation",
    modalVariation: GlobalNavVariation.HCP_REPUTATION,
  },
  {
    href: ACTIVATED_INSIGHTS_PATH,
    hostKey: "activatedInsightsAppHost",
    title: "Great Place to Work",
    permissionsKey: "canAccessActivatedInsights",
    modalVariation: GlobalNavVariation.ACTIVATED_INSIGHTS,
  },
  {
    href: ACTIVATED_INSIGHTS_PATH,
    hostKey: "activatedInsightsAppHost",
    title: "U.S. News Best Senior Living",
    permissionsKey: "canAccessActivatedInsights",
    modalVariation: GlobalNavVariation.ACTIVATED_INSIGHTS,
  },
];

export function GlobalNavTabs() {
  const [isExperienceOpen, setIsExperienceOpen] = useState(false);
  const [isRecognitionOpen, setIsRecognitionOpen] = useState(false);
  const { hosts } = useGlobalNavContext();
  const { permissions } = usePermissionsContext();
  const { setIsCrossModalOpen, setCrossModalVariation } =
    useCrossModalContext();

  const makeOnClickHandler =
    ({
      permissionsKey,
      modalVariation,
    }: {
      permissionsKey: keyof GlobalNavPermissions;
      modalVariation: GlobalNavVariation;
    }) =>
    (e: MouseEvent<HTMLElement>) => {
      if (!permissions[permissionsKey]) {
        e.preventDefault();
        if (modalVariation === GlobalNavVariation.RECRUIT) {
          window.open(hosts.recruitAppHost);
          return;
        }
        setCrossModalVariation(modalVariation);
        setIsCrossModalOpen(true);
        return;
      }
    };

  return (
    <>
      <div className="hidden h-full lg:flex">
        <GlobalNavTab
          href={makeUrl(hosts.recruitAppHost, RECRUIT_PATH)}
          iconStart={<HCPRecruitIcon className="recruit-tab" />}
          onClick={makeOnClickHandler({
            permissionsKey: "canAccessRecruit",
            modalVariation: GlobalNavVariation.RECRUIT,
          })}
        >
          <></>
        </GlobalNavTab>
        <GlobalNavTab
          href={makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_PATH)}
          iconStart={<HCPTrainingIcon className="training-tab" />}
          onClick={makeOnClickHandler({
            permissionsKey: "canAccessHcpTraining",
            modalVariation: GlobalNavVariation.HCP_TRAINING,
          })}
        >
          <></>
        </GlobalNavTab>
        <GlobalNavTab
          href={makeUrl(hosts.pinnacleRetainAppHost, PINNACLE_RETAIN_PATH)}
          iconStart={<PinnacleRetainIcon className="retain-tab" />}
          onClick={makeOnClickHandler({
            permissionsKey: "canAccessPinnacleRetain",
            modalVariation: GlobalNavVariation.PINNACLE_RETAIN,
          })}
        >
          <></>
        </GlobalNavTab>
        <GlobalNavDropdown>
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button
                  as={GlobalNavTab}
                  component="span"
                  href={makeUrl(
                    hosts.hcpExperienceAppHost,
                    HCP_EXPERIENCE_PATH
                  )}
                  iconStart={<HCPExperienceIcon className="experience-tab" />}
                  className={clsx(open && "bg-white/25")}
                >
                  <></>
                  <ChevronIcon className="ml-2" />
                </Menu.Button>
                <GlobalNavDropdownMenuItems className="w-80">
                  {experienceItems.map((item) => (
                    <GlobalNavDropdownMenuItem
                      className="hover:experience"
                      key={item.href}
                      iconStart={item.icon}
                      href={makeUrl(hosts[item.hostKey], item.href)}
                      onClick={makeOnClickHandler(item)}
                    >
                      {item.title}
                    </GlobalNavDropdownMenuItem>
                  ))}
                </GlobalNavDropdownMenuItems>
              </>
            )}
          </Menu>
        </GlobalNavDropdown>
        <GlobalNavDropdown>
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button
                  as={GlobalNavTab}
                  component="span"
                  href={makeUrl(
                    hosts.hcpExperienceAppHost,
                    HCP_EXPERIENCE_PATH
                  )}
                  iconStart={<RecognitionIcon className={"recognition-tab"} />}
                  className={clsx(open && "bg-white/25")}
                >
                  <ChevronIcon className="ml-2" />
                </Menu.Button>
                <GlobalNavDropdownMenuItems className="w-80">
                  {recognitionItems.map((item) => (
                    <GlobalNavDropdownMenuItem
                      key={item.href}
                      href={makeUrl(hosts[item.hostKey], item.href)}
                      className="hover:recognition"
                      onClick={makeOnClickHandler(item)}
                    >
                      {item.title}
                    </GlobalNavDropdownMenuItem>
                  ))}
                </GlobalNavDropdownMenuItems>
              </>
            )}
          </Menu>
        </GlobalNavDropdown>
      </div>
      <div className="h-full lg:hidden">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button
                as={GlobalNavTab}
                component="span"
                href={makeUrl(hosts.hcpExperienceAppHost, HCP_EXPERIENCE_PATH)}
                className={clsx(open && "bg-white/25")}
              >
                <MoreIcon />
              </Menu.Button>
              <GlobalNavDropdownMenuItems className="inset-x-4">
                <GlobalNavDropdownMenuItem
                  href={makeUrl(hosts.recruitAppHost, RECRUIT_PATH)}
                  iconStart={<DarkHCPRecruitIcon className={"recruit-drop"} />}
                  className="hover:recruit"
                  onClick={makeOnClickHandler({
                    permissionsKey: "canAccessActivatedInsights",
                    modalVariation: GlobalNavVariation.ACTIVATED_INSIGHTS,
                  })}
                >
                  <></>
                </GlobalNavDropdownMenuItem>
                <GlobalNavDropdownMenuItem
                  href={makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_PATH)}
                  iconStart={
                    <DarkHCPTrainingIcon className={"training-drop"} />
                  }
                  className="hover:training"
                  onClick={makeOnClickHandler({
                    permissionsKey: "canAccessHcpTraining",
                    modalVariation: GlobalNavVariation.HCP_TRAINING,
                  })}
                >
                  <></>
                </GlobalNavDropdownMenuItem>
                <GlobalNavDropdownMenuItem
                  href={makeUrl(
                    hosts.pinnacleRetainAppHost,
                    PINNACLE_RETAIN_PATH
                  )}
                  className="hover:retain"
                  iconStart={
                    <DarkPinnacleRetainIcon className={"retain-drop"} />
                  }
                  onClick={makeOnClickHandler({
                    permissionsKey: "canAccessPinnacleRetain",
                    modalVariation: GlobalNavVariation.PINNACLE_RETAIN,
                  })}
                >
                  <></>
                </GlobalNavDropdownMenuItem>
                <GlobalNavDropdownItem
                  as="button"
                  className="hover:experience"
                  iconStart={
                    <DarkHCPExperienceIcon className={"experience-drop"} />
                  }
                  iconEnd={
                    <ChevronIcon
                      className={clsx({ "rotate-180": isExperienceOpen })}
                    />
                  }
                  onClick={(e) => {
                    // stop menu from closing
                    e.stopPropagation();
                    setIsExperienceOpen((isOpen) => !isOpen);
                  }}
                >
                  <></>
                </GlobalNavDropdownItem>
                <TransitionHeight show={isExperienceOpen}>
                  <div className="bg-gray-100">
                    {experienceItems.map((item) => (
                      <GlobalNavDropdownMenuItem
                        href={makeUrl(hosts[item.hostKey], item.href)}
                        className="hover:experience"
                        onClick={makeOnClickHandler(item)}
                      >
                        {item.title}
                      </GlobalNavDropdownMenuItem>
                    ))}
                  </div>
                </TransitionHeight>
                <GlobalNavDropdownItem
                  as="button"
                  className="hover:recognition"
                  iconStart={
                    <DarkRecognitionIcon className={"recognition-drop"} />
                  }
                  iconEnd={
                    <ChevronIcon
                      className={clsx({ "rotate-180": isRecognitionOpen })}
                    />
                  }
                  onClick={(e) => {
                    // stop menu from closing
                    e.stopPropagation();
                    setIsRecognitionOpen((isOpen) => !isOpen);
                  }}
                >
                  <></>
                </GlobalNavDropdownItem>
                <TransitionHeight show={isRecognitionOpen}>
                  <div className="bg-gray-100">
                    {recognitionItems.map((item) => (
                      <GlobalNavDropdownMenuItem
                        href={makeUrl(hosts[item.hostKey], item.href)}
                        className="hover:recognition"
                        onClick={makeOnClickHandler(item)}
                      >
                        {item.title}
                      </GlobalNavDropdownMenuItem>
                    ))}
                  </div>
                </TransitionHeight>
              </GlobalNavDropdownMenuItems>
            </>
          )}
        </Menu>
      </div>
    </>
  );
}
