import React from "react";
import HCPLogo from "../assets/global-nav/icon-ai-formerly-HCP.svg";
import LogoOnly from "../assets/global-nav/logo-hcp.svg";
import { CLICK_LOGO_EVENT_NAME } from "../constants/constants";

const onClickLogo: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  e.currentTarget.dispatchEvent(
    new CustomEvent(CLICK_LOGO_EVENT_NAME, { bubbles: true, composed: true })
  );
};

export function GlobalNavLogo() {
  return (
    <button type="button" onClick={onClickLogo}>
      <HCPLogo className={"hidden h-10 lg:block"} />
      <LogoOnly className={"block h-10 lg:hidden"} />
    </button>
  );
}
