export const PUBLIC_HCP_EXPERIENCE_APP_HOST =
  process.env.PUBLIC_HCP_EXPERIENCE_APP_HOST;

export const PUBLIC_HCP_REPUTATION_APP_HOST =
  process.env.PUBLIC_HCP_REPUTATION_APP_HOST;

export const PUBLIC_HCP_TRAINING_APP_HOST =
  process.env.PUBLIC_HCP_TRAINING_APP_HOST;

export const PUBLIC_ACTIVATED_INSIGHTS_APP_HOST =
  process.env.PUBLIC_ACTIVATED_INSIGHTS_APP_HOST;

export const PUBLIC_PINNACLE_RETAIN_APP_HOST =
  process.env.PUBLIC_PINNACLE_RETAIN_APP_HOST;

export const PUBLIC_PINNACLE_QI_APP_HOST =
  process.env.PUBLIC_PINNACLE_QI_APP_HOST;

export const PUBLIC_PINNACLE_CAHPS_APP_HOST =
  process.env.PUBLIC_PINNACLE_CAHPS_APP_HOST;

export const PUBLIC_RECRUIT_APP_HOST = process.env.PUBLIC_RECRUIT_APP_HOST;
