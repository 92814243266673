import { clsx } from "clsx";
import React, {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  cloneElement,
  forwardRef,
} from "react";

type GlobalNavTabComponentProps = {
  children: ReactNode;
  className?: string;
  href: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

type GlobalNavTabProps = GlobalNavTabComponentProps & {
  component?:
    | ForwardRefExoticComponent<
        GlobalNavTabComponentProps & RefAttributes<HTMLAnchorElement>
      >
    | string;
  iconStart?: React.ReactElement<{
    className?: string;
  }>;
};

export const GlobalNavTab = forwardRef<HTMLAnchorElement, GlobalNavTabProps>(
  (
    {
      children,
      className,
      component: Component = "a",
      href,
      iconStart,
      onClick,
    },
    ref
  ) => {
    return (
      <Component
        className={clsx(
          "flex h-full cursor-pointer flex-row items-center px-2 text-lg leading-7 text-white no-underline hover:bg-white/25 group-[.global-nav-ai]:text-xl",
          className
        )}
        href={href}
        onClick={onClick}
        ref={ref}
      >
        {iconStart &&
          cloneElement(iconStart, {
            className: clsx("mr-4", iconStart.props.className),
          })}
        {children}
      </Component>
    );
  }
);
