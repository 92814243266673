import * as env from "./env";

// APP_HOSTS
export const HCP_EXPERIENCE_APP_HOST =
  env.PUBLIC_HCP_EXPERIENCE_APP_HOST ?? "https://my.homecarepulse.com";
export const HCP_REPUTATION_APP_HOST =
  env.PUBLIC_HCP_REPUTATION_APP_HOST ?? HCP_EXPERIENCE_APP_HOST;
export const HCP_TRAINING_APP_HOST =
  env.PUBLIC_HCP_TRAINING_APP_HOST ?? "https://learn.homecarepulse.com";
export const ACTIVATED_INSIGHTS_APP_HOST =
  env.PUBLIC_ACTIVATED_INSIGHTS_APP_HOST ?? "https://app.gptw.care";
export const PINNACLE_RETAIN_APP_HOST =
  env.PUBLIC_PINNACLE_RETAIN_APP_HOST ?? "https://app.retainyouremployees.com";
export const PINNACLE_QI_APP_HOST =
  env.PUBLIC_PINNACLE_QI_APP_HOST ?? "https://my.pinnacleqi.com";
export const PINNACLE_CAHPS_APP_HOST =
  env.PUBLIC_PINNACLE_CAHPS_APP_HOST ?? PINNACLE_QI_APP_HOST;
export const RECRUIT_APP_HOST =
  env.PUBLIC_RECRUIT_APP_HOST ?? "https://app.preintent.com";

// MAIN URLS
export const HCP_EXPERIENCE_PATH = "/cip/experience";
export const HCP_REPUTATION_PATH = "/cip/reputation";
export const HCP_TRAINING_PATH = "/cip/training";
export const ACTIVATED_INSIGHTS_PATH = "/";
export const PINNACLE_RETAIN_PATH = "/";
export const PINNACLE_QI_PATH = "/";
export const PINNACLE_CAHPS_PATH = "/";
export const RECRUIT_PATH = "/";

// LOGIN URLS
export const HCP_EXPERIENCE_LOGIN_PATH = "/";
export const HCP_REPUTATION_LOGIN_PATH = "/";
export const HCP_TRAINING_LOGIN_PATH = "/";
export const ACTIVATED_INSIGHTS_LOGIN_PATH = "/account-login";
export const PINNACLE_RETAIN_LOGIN_PATH = "/";
export const PINNACLE_QI_LOGIN_PATH = "/";
export const PINNACLE_CAHPS_LOGIN_PATH = "/";

// LOGOUT URLS
export const HCP_EXPERIENCE_LOGOUT_PATH = "/cip/logout";
export const HCP_REPUTATION_LOGOUT_PATH = "/cip/logout";
export const HCP_TRAINING_LOGOUT_PATH = "/logout";
export const ACTIVATED_INSIGHTS_LOGOUT_PATH = "/backend/logout";
export const PINNACLE_RETAIN_LOGOUT_PATH = "/logout";
export const PINNACLE_QI_LOGOUT_PATH = "/logout";
export const PINNACLE_CAHPS_LOGOUT_PATH = "/logout";

// DEMO URLS
export const DEMO_URL =
  "https://www.homecarepulse.com/customer-product-inquiry/";
